import React          from 'react';
import { graphql }    from 'gatsby';
import { GiMetalBar } from 'react-icons/gi';
import styled         from '@emotion/styled';
import Img            from 'gatsby-image';

import Wrapper          from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer           from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading          from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction     from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';
import Lightbox         from '@interness/web-core/src/components/media/Lightbox/Lightbox';

const SDisplay = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SDisplayItem = styled.div`
  width: ${props => props.width};
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 640px) {
    width: 100%;
  }

  > div {
    margin-bottom: 20px;
    position: relative;
    border: 5px solid ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'} !important;
  }

  h4 {
    background-color: ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'} !important;
    color: ${props => props.theme.colorMode === 'dark' ? '#333' : '#fff'} !important;
    text-transform: uppercase;
    padding: 10px 0;
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }

  h5 {
    font-size: 1.2rem;
    font-weight: 300;
    text-align: left;
    margin-top: 10px;
  }

  p {
    text-align: justify;
    font-size: 0.9rem;
  }

  a {
    position: absolute;
    bottom: -42px;
  }
`;


const EinblickePage = (props) => {
  return (
    <Wrapper>
      <Spacer/>
      <Heading icon={<GiMetalBar/>} subtitle="Das erwartet Sie in unserem Trauringstudio">Einblicke</Heading>
      <Spacer/>
      <div>
        <p>Unser Studio ist der ideale Ort, um die perfekten Ringe für den wichtigsten Tag in Ihrem Leben zu finden. Bei
          uns dreht sich alles um Trauringe, Eheringe, Verlobungsringe und Antragsringe. Unser Ziel ist es, Ihnen ein
          emotionales und unvergessliches Erlebnis bei der Auswahl Ihrer Ringe zu bieten.</p>
        <p>Unsere Kollektionen bestechen durch ein breites Spektrum an einzigartigen Designs und hochwertigen
          Materialien. Ob Sie nach klassischen, zeitlosen Eheringen oder dem perfekten Antragsring suchen, bei uns
          werden Sie fündig. Wir stehen Ihnen mit Rat und Tat zur Seite, um Ihre individuellen Wünsche und Vorstellungen
          zu verwirklichen.</p>
        <p>Ein weiterer wichtiger Aspekt für uns ist Nachhaltigkeit. Wir sind stolz darauf, nachhaltige und ethische
          Praktiken in unserem Geschäftsumfeld zu fördern. Unsere Ringe werden mit größter Sorgfalt und Verantwortung
          gegenüber der Umwelt hergestellt. Wir bieten Ihnen die Möglichkeit, Ringe aus recycelten Materialien oder
          ethisch gewonnenen Diamanten zu wählen, ohne Kompromisse bei der Qualität einzugehen.</p>
        <p>Besuchen Sie unser Trauringstudio Stauss in Rottweil in der Hochbrücktorstr. 1, um Ihre Traumringe zu
          entdecken und Ihre Liebesgeschichte mit uns zu beginnen. Wir sind stets bestrebt, Ihre Erwartungen zu
          übertreffen und Ihnen die Ringe zu bieten, die Ihre Liebe und Verbindung perfekt symbolisieren. Wir freuen uns
          auf Ihren Besuch!</p>
      </div>
      <Spacer/>
      <SDisplay>
        {props.data.team.nodes.map(item => (
          <SDisplayItem width={'45%'} key={item.name}>
            <div>
              <Img fluid={item.portrait.localFile.childImageSharp.fluid} alt={item.name}/>
              <h4>{item.name}</h4>
            </div>
          </SDisplayItem>
        ))}
      </SDisplay>
      <Spacer/>
      <ResponsiveIFrame maxHeight={56.25} src="https://www.youtube-nocookie.com/embed/H2z_b7HXtsU" title="YoutubeVideo"
                        type="youtube"/>
      <Spacer/>
      <ResponsiveIFrame maxHeight={56.25} src="https://www.youtube-nocookie.com/embed/ATyA_ilPw5c" title="YoutubeVideo"
                        type="youtube"/>
      <Spacer/>
      <div>
        <Lightbox images={props.data.gallery.images} thumbnails={props.data.gallery.thumbnails} fromApi/>
      </div>
      <Spacer/>
      <CallToAction description="Vereinbaren Sie noch heute Ihren Termin zur Trauringberatung"
                    button="Zur Terminvereinbarung" link="/terminvereinbarung"
                    imageFileTitle="theme-sonora-call-to-action-background-trauringe"/>
      <Spacer/>
    </Wrapper>
  )
};

export default EinblickePage;

export const query = graphql`
    query {
        gallery: directusMediaCollection(name: {eq: "einblicke"}) {
            ...LightboxGalleryGrid
        }
        team: allDirectusTeamMembers(filter: {id: {ne: "dummy"}}) {
            nodes {
                name
                portrait {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 530, quality: 95) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                translations {
                    language
                    description
                }
            }
        }
    }
`;
